import request from '@/util/request'
export default{
	//登录
	login(data){
		return request({url: '/trader/login/doLogin',method: 'post',data})		
	},	
	//登出
	logout(data){
		return request({url: '/trader/login/logout',method: 'post',data})		
	},	
	//谷歌验证检查
	validateGcode(data){
		return request({url: '/trader/login/validateGcode',method: 'post',data})		
	},
	//谷歌验证码绑定
	bingGoogle(data){
		return request({url: '/trader/login/bindGoogle',method: 'post',data})		
	},
	//订单状态
	stateList(data){
		return request({url: '/trader/order/stateList',method: 'post',data})		
	},
	//代付订单状态
	outStateList(data){
		return request({url: '/trader/order/outStateList',method: 'post',data})		
	},
	//支付方式
	// $hasRepay = input('hasRepay', 1);//是否查询自动代付（支付类型） 1-是 2-否
	payType(data){
		return request({url: '/trader/order/payType',method: 'post',data})		
	},
	//订单列表
	orderInList(data){
		return request({url: '/trader/order/orderInList',method: 'post',data})		
	},
	//订单列表 统计
	orderInTotal(data){
		return request({url: '/trader/order/orderInTotal',method: 'post',data})		
	},
	
	//交易报表
	orderReport(data){
		return request({url: '/trader/order/report',method: 'post',data})		
	},
	//代付订单
	orderOutList(data){
		return request({url: '/trader/order/orderOutList',method: 'post',data})		
	},
	
	//修改账号密码
	/*  $username = input('username');
        $password = input('password');
        $code = input('code');// 谷歌验证码 */
	setPassword(data){
		return request({url: 'trader/user/setPassword',method: 'post',data})		
	},
	//修改代付密码
	/*  $username = input('username');
        $password = input('password');
        $code = input('code');// 谷歌验证码 */
	setPayPassword(data){
		return request({url: 'trader/user/setPayPassword',method: 'post',data})		
	},
	/* 修改公钥
	  $username = input('username');
        $key = input('key');
        $code = input('code');// 谷歌验证码*/
	setSignKey(data){
		return request({url: 'trader/user/setSignKey',method: 'post',data})		
	},
	/* 查询账号信息 
	 $username = input('username');
	 */
	userInfo(data){
		return request({url: 'trader/user/info',method: 'post',data,allowRepetition:true})		
	},
	//实时代付
	createOrder(data){
		return request({url: 'trader/order/create',method: 'post',data,allowRepetition:true})		
	},
	//主界面  统计数据
	commonTotal(data){
		return request({url: 'trader/order/commonTotal',method: 'post',data,allowRepetition:true})		
	},
	// 余额账变表
	moneyChangeList(data){
		return request({url: 'trader/order/moneyChangeList',method: 'post',data,allowRepetition:true})		
	},
}